import { ITeam } from 'components/modals/GroupsCreateEdit/models';

export type TAppPolicyRulePlatform = 'android' | 'ios';

export type TAppPolicyRuleActionKey = 'allow' | 'deny' | 'ooc';

export type TAppPolicyRuleAction =
  | 'Mark as Safe'
  | 'Mark as Suspicious'
  | 'Out of Compliance (OOC)';

export type TAppPolicyRuleCriteriaKey =
  | 'developer_package_version'
  | 'developer_package'
  | 'developer'
  | 'package_version'
  | 'package';

export type TAppPolicyRuleCriteria =
  | 'Developer Signature + Package ID + Version'
  | 'Developer Signature + Package ID'
  | 'Developer Signature'
  | 'Package ID + Version'
  | 'Package ID';

export interface IAppPolicyRuleCriteria {
  id: string;
  uuid?: string;
  package: string;
  version: string;
}

export interface IAppPolicyGroup {
  id: string;
  name: string;
}

export interface IPolicyRulesResult {
  modified: number;
  groups?: IAppPolicyGroup[];
  policyDetails: IAppPolicyRule[];
  triggerRules: IAppPolicyTriggerRule[];
}

export interface IAppPolicyTriggerRule {
  all: string[];
  any: string[];
  created: number;
  createdBy?: string;
  description: string;
  enabled: boolean;
  id?: string;
  modified: number;
  name: string;
  existingTriggerRule?: boolean;
  deviceCount?: number;
  appCount?: number;
  modifiedBy?: string;
  deleted?: boolean;
  platform?: string;
  common?: boolean;
}

export interface IAppPolicyRule {
  criteria: IAppPolicyRuleCriteria[];
  id: number;
  uuid?: string;
  name: string;
  order: number;
}

export interface IAppPolicy {
  accountId: string;
  created: string;
  id: string;
  modified: string;
  name: string;
  rules: IAppPolicyRule[];
  triggerRules: IAppPolicyTriggerRule[];
  team: ITeam;
  groups?: IAppPolicyGroup[];
}

export interface IAppPolicyRuleTableItem {
  id: string;
  name: string;
  platform: TAppPolicyRulePlatform;
  action: TAppPolicyRuleAction;
  criteria: TAppPolicyRuleCriteria;
  numRows: number;
}

export type TPolicy =
  | 'App'
  | 'AppSettings'
  | 'Phishing'
  | 'Privacy'
  | 'Threat'
  | 'Network'
  | 'Branding'
  | 'OsRisk'
  | 'Dormancy';

export const TpolicyMap: Record<TPolicy, string> = {
  App: 'App',
  AppSettings: 'App Settings',
  Phishing: 'Phishing',
  Privacy: 'Privacy',
  Threat: 'Threat',
  Network: 'Network',
  Branding: 'Branding',
  OsRisk: 'OS Risk',
  Dormancy: 'Device Inactivity',
};

export interface ISelectItem<T = number | string | null> {
  accountBounded?: boolean;
  label: string;
  value: T;
  team?: ITeam | null | undefined;
  created?: string | Date;
  connection?: {
    id: string;
  };
}
