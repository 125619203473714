import React, { useState, Fragment, useEffect } from 'react';
import _ from 'lodash';
import { withRouter, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';

// mui
import Typography from '@material-ui/core/Typography';

// components
import PublicComponentWrapper from 'components/wrappers/PublicComponentWrapper';
import SSOLoginButton from 'components/entry/login/SSOLoginButton';
import ZButton from 'UI/Buttons/ZButton';

// actions
import { postLoginCredentials, resetPasswordOnLogin } from 'api/SignupService';

// services
import { fetchPasswordPolicyForPasswordExpiry } from 'api/PasswordPolicyService';
import { FormikPasswordField } from 'components/inputs/FormikPasswordField';
import { FormikTextField } from 'components/inputs/FormikTextField';

import {
  csvInjectionRegex,
  csvInjectionErrorMessage,
  looksGenerallyLikeAnEmailRegex,
  looksGenerallyLikeAnEmailRegexErrorMessage,
} from 'utils/componentUtils';

import PasswordRulesBox from 'components/main/common/PasswordRulesBox';
import { passwordErrorHelper } from './password.mappings';

const Login = (props) => {
  /* eslint-disable-next-line no-unused-vars */
  const [resendFlag, setResendFlag] = useState(false);
  // const [ssoEnabled, setSsoEnabled] = useState(false);
  const [email, setEmail] = useState('');
  const [resetPasswordFlag, setResetPasswordFlag] = useState(false);
  const [customPasswordRules, setCustomPasswordRules] = useState({});
  const [generalNetworkError, setGeneralNetworkError] = useState('');
  const [hideLinks, setHideLinks] = useState(false);
  const [showSSOButton, setShowSSOButton] = useState(false);

  const { t } = useTranslation();

  const user = useSelector((state) => state.auth.user);
  const superUser = useSelector((state) => state.auth.superUser);
  const partnerUser = useSelector((state) => state.auth.partnerUser);
  const { sso } = useSelector((state) => state.auth);
  const { cacMode } = useSelector(
    (state) => state?.uiSettings?.systemProperties
  );
  // if there is a user direct the user to dashboard
  useEffect(() => {
    if (user.isAuthenticated || superUser.isAuthenticated) {
      return props.history.replace('/console');
    }
    if (partnerUser.isAuthenticated) {
      return props.history.replace('/partner');
    }
  }, [
    partnerUser.isAuthenticated,
    props.history,
    superUser.isAuthenticated,
    user.isAuthenticated,
  ]);

  useEffect(() => {
    if (
      (sso.enabled === true && sso.disableLocalLogins === false) ||
      sso.enabled === false
    ) {
      setHideLinks(true);
    }
    if (sso.enabled === true) {
      setShowSSOButton(true);
    }
  }, [sso]);

  useEffect(() => {
    if (!resetPasswordFlag) return;

    fetchPasswordPolicyForPasswordExpiry(email)
      .then(({ data }) => {
        setCustomPasswordRules(data);
      })
      .catch((error) => {
        console.log('Error: ', error);
      });
  }, [email, resetPasswordFlag]);

  function handleSubmit(values, { setErrors }) {
    const { location } = props;

    // For password expiry password change
    if (resetPasswordFlag) {
      if (_.isEmpty(_.get(values, 'newPassword')))
        return setErrors({ newPassword: 'Required' });

      if (_.isEmpty(_.get(values, 'confirmedPassword')))
        return setErrors({ confirmedPassword: 'Required' });

      resetPasswordOnLogin(values)
        .then(() => {
          setResetPasswordFlag(false);
          props.history.push('/');
        })
        .catch((error) => {
          const message = _.get(error, 'response.data');

          // If error response is an array, it is password violations
          if (Array.isArray(message)) {
            const displayedErrors = passwordErrorHelper(
              _.get(error, 'response.data'),
              t
            );

            return setErrors({ newPassword: displayedErrors });
          }

          return setGeneralNetworkError(message.message);
        });
    } else {
      // Regular login
      postLoginCredentials(values)
        .then(() => {
          if (
            location.state &&
            location.state.from &&
            location.state.from.pathname !== '/logout'
          ) {
            props.history.push(location.state.from);
          } else {
            props.history.push('/admin');
          }
        })
        .catch((error) => {
          const status = _.get(error, 'response.status', 401);
          const message = _.get(
            error,
            'response.data.message',
            'Service Unavailable.'
          );
          if (status === 412) {
            if (message === 'User credentials have expired') {
              setEmail(values.email);
              return setResetPasswordFlag(true);
            } else {
              return setErrors({ email: 'Invalid credentials' });
            }
          }
          if (status === 401) {
            return setErrors({ email: 'Invalid credentials' });
          }
          return setGeneralNetworkError(message);
        });
    }
  }

  return (
    <PublicComponentWrapper>
      <Formik
        validationSchema={LoginSchema}
        validateOnBlur
        enableReinitialize
        initialValues={{ email: '', password: '' }}
        onSubmit={handleSubmit}
      >
        {({ dirty, isSubmitting, values }) => {
          return (
            <>
              {_.has(props.location, 'state.existingUser') && (
                <Typography variant="h6" className="entry-label" align="center">
                  Thank you for confirming your email! You already have an
                  account. Sign-in or reset your password.
                </Typography>
              )}

              {resetPasswordFlag && (
                <Typography variant="h6" className="entry-label" align="center">
                  Your password has expired, you can update your password below.
                </Typography>
              )}

              <Form>
                <Field
                  name="email"
                  label="Email"
                  inputProps={{ 'data-testid': 'email-input' }}
                  component={FormikTextField}
                  classlist={{ label: 'entry-label' }}
                />

                <Field
                  name="password"
                  label="Password"
                  inputProps={{ 'data-testid': 'password-input' }}
                  component={FormikPasswordField}
                  classlist={{ label: 'entry-label' }}
                />

                {resetPasswordFlag && (
                  <>
                    <Field
                      name="newPassword"
                      label="New Password"
                      component={FormikPasswordField}
                      classlist={{ label: 'entry-label' }}
                    />
                    <Field
                      name="confirmedPassword"
                      label="Confirm New Password"
                      component={FormikPasswordField}
                      classlist={{ label: 'entry-label' }}
                    />
                  </>
                )}

                <ZButton
                  fullWidth
                  buttonType="submit"
                  styleName="login"
                  color="primary"
                  buttonText={
                    !resetPasswordFlag ? 'Sign In' : 'Update Password'
                  }
                />

                {resendFlag && (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      flexDirection: 'column',
                    }}
                  >
                    <Typography
                      variant="h6"
                      className="entry-label"
                      align="center"
                    >
                      Your email has expired. Please verify your email.
                    </Typography>

                    <Link to="/resend-validation">Resend Validation Email</Link>
                  </div>
                )}
                {!_.isEmpty(generalNetworkError) && (
                  <Typography variant="body1" color="error" align="center">
                    {generalNetworkError}
                  </Typography>
                )}

                <div style={{ textAlign: 'center' }}>
                  {hideLinks && (
                    <p>
                      Forgot password?&nbsp;
                      <Link to="/forgot-password">Reset Password</Link>
                    </p>
                  )}

                  {showSSOButton && (
                    <Fragment>
                      <div className="or-divider">OR</div>
                      <SSOLoginButton />
                    </Fragment>
                  )}

                  {_.get(props.location, 'state.error_type') === 'sso' && (
                    <Typography variant="body1" color="error" align="center">
                      {props.location.state.message}
                    </Typography>
                  )}
                </div>
              </Form>
            </>
          );
        }}
      </Formik>
      {resetPasswordFlag && (
        <PasswordRulesBox customPasswordRules={customPasswordRules} />
      )}
    </PublicComponentWrapper>
  );
};

const LoginSchema = Yup.object().shape({
  email: Yup.string()
    .matches(
      looksGenerallyLikeAnEmailRegex,
      looksGenerallyLikeAnEmailRegexErrorMessage
    )
    .matches(csvInjectionRegex, csvInjectionErrorMessage)
    .required('Required'),
  password: Yup.string(),
  newPassword: Yup.string().test(
    'notSameAsOldPassword',
    'New password cannot be the same as the old password',
    function (newPassword) {
      const { password } = this.parent;
      if (newPassword === password) {
        return false;
      }
      return true;
    }
  ),
  confirmedPassword: Yup.string().oneOf(
    [Yup.ref('newPassword', 'Passwords must match'), ''],
    'Passwords must be the same'
  ),
});

export default withRouter(Login);
